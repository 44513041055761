import { ReportEditorContext } from '@views/settings/reports/report_editor.context'
import { Localization } from '@lib/i18n/localization'
import { Authorization } from '@modules/authorization/authorization'
import JobBoosterService from '@services/job_booster_service'
import {
    abortAndCreateNewController,
    createErrorStatus,
    createPendingStatus,
    createSuccessStatus,
    getErrorStructure,
    STATUS_PENDING,
} from '@utils/request_statuses'
import { O, tryCatch } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { Dictionaries } from '@modules/dictionaries/dictionaries'
import { applicationStatus } from '@lib/application_status/application_status.jsx'
import { dictionaries } from '@modules/process_status/process_status.js'
import { ExitConfirmation } from '@views/misc/exit_confirmation/exit_confirmation.process'
import {
    disableTopLevelOrganisationNodes,
    enableNodesByCurrency,
    getNodesByIds,
} from '@modules/dictionaries/organization-hierarchy'
import { endOfMonth, getMonth, getYear } from 'date-fns'
import { haveSameElements } from '@prospective/pms-js-utils'
import { useMemoLast, stateOf, useCallback, useMemo, useOnce, useState, Process } from '@prospective/process-router'
import { useView } from '@lib/view_context/view_context_hooks'

const service = JobBoosterService
const logger = Logger('ReportEditorProcess', CATEGORIES.MAIN)
const today = new Date()
const previousMonthStart = new Date(getYear(today), getMonth(today) - 1)
const previousMonthEnd = endOfMonth(previousMonthStart)
const testFilterParams = {
    from: previousMonthStart,
    to: previousMonthEnd,
    filters: {
        hk_id: [],
        recruiter_id: [],
        medium_id: [],
        'attributes.field_of_activity.id.keyword': [],
        'attributes.BRANCHE.id.keyword': [],
        ats_id: [],
        auftrags_id: [],
        stelle_id: [],
        publication_id: [],
        interner_stellentitel: [],
    },
}

const compareReports = (report1, report2) => {
    return (
        report1.id === report2.id &&
        report1.name === report2.name &&
        report1.created === report2.created &&
        report1.fields.every(
            ({ sourceType, field }, index) =>
                report2.fields[index].sourceType === sourceType && report2.fields[index].field === field
        ) &&
        haveSameElements(report1.filters, report2.filters) &&
        report1.hkId === report2.hkId &&
        report1.hkName === report2.hkName &&
        report1.description === report2.description &&
        report1.sql === report2.sql
    )
}
const reportTemplate = {
    id: null,
    name: undefined,
    created: null,
    sql: 'select * from :result_data',
    filters: [],
    fields: [],
    hkId: undefined,
    hkName: undefined,
    description: undefined,
}

export const ReportEditorProcess = Process(({ process, params }) => {
    const once = useOnce()
    const view = useView(ReportEditorContext)
    const { locale } = stateOf(Localization)
    const { permissions, proAnalyticsPermissions } = stateOf(Authorization)
    const { organizationStructure } = stateOf(Dictionaries)
    const hasPermission = proAnalyticsPermissions.reports.management.permissions.write
    const id = params.id
    const [reportRequestStatus, setReportRequestStatus] = useState()
    const [report, setReport] = useState(reportTemplate)
    const [columnsRequestStatus, setColumnsRequestStatus] = useState()
    const [columns, setColumns] = useState()
    const [saveRequestStatus, setSaveRequestStatus] = useState()
    const [testRequestStatus, setTestRequestStatus] = useState()
    const [queryTestResult, setQueryTestResult] = useState()
    const [queryTestAbortController, setQueryTestAbortController] = useState()

    process.beforeTerminate(async signal => {
        if (!proAnalyticsPermissions.reports.management.permissions.write) return

        const editedReport = buildReport(view.values)
        if (!compareReports(report, editedReport)) {
            const cancelConfirmation = await ExitConfirmation()
            if (cancelConfirmation === 'cancel') return navigation.prevent('User cancelled navigation')
        }
    })

    const getReport = useMemoLast(async reportId => {
        if (!hasPermission) return
        setReportRequestStatus(createPendingStatus())
        const [error, report] = await tryCatch(service.getReport)(reportId)
        if (error) {
            const { logNumber } = logger.error.withError(error, `Could not get report ${reportId} from the service`)
            const errorMessage = locale('reports.getReportError', { logNumber })
            setReportRequestStatus(createErrorStatus({ logNumber, error: errorMessage }))
            setReport({ ...reportTemplate })
            return
        }
        setReportRequestStatus(createSuccessStatus())
        return report
    })

    const getColumns = useMemo(async () => {
        if (!hasPermission) return
        setColumnsRequestStatus(createPendingStatus())
        const [error, columns] = await tryCatch(service.getReportColumns)()
        if (error) {
            const { logNumber } = logger.error.withError(error, `Could not get report columns from the service`)
            const errorMessage = locale('reports.getReportColumnsError', { logNumber })
            setColumnsRequestStatus(createErrorStatus({ logNumber, error: errorMessage }))
            return
        }
        setColumnsRequestStatus(createSuccessStatus())
        return columns
    }, [])

    const onCancel = useCallback(async () => {
        if (!proAnalyticsPermissions.reports.management.permissions.write) {
            process.exit()
            return
        }

        const editedReport = buildReport(view.values)

        if (!compareReports(report, editedReport)) {
            const cancelConfirmation = await ExitConfirmation()
            if (cancelConfirmation === 'discard') process.exit()
        } else {
            process.exit()
        }
    })

    // const onLeave = useCallback(async navigation => {
    //     if (!permissions.settings.reports.management.permissions.write) return

    //     const editedReport = buildReport(viewContext.data)
    //     if (!compareReports(report, editedReport)) {
    //         const cancelConfirmation = await ExitConfirmation()
    //         if (cancelConfirmation === 'cancel') return navigation.prevent('User cancelled navigation')
    //     }
    // })

    const buildReport = useCallback(data => {
        return {
            id: data.reportNumber || null,
            nameDe: data.reportNameDe || undefined,
            nameFr: data.reportNameFr || undefined,
            nameIt: data.reportNameIt || undefined,
            nameEn: data.reportNameEn || undefined,
            created: report.created || null,
            sqlDe: data.queryDe || undefined,
            sqlFr: data.queryFr || undefined,
            sqlIt: data.queryIt || undefined,
            sqlEn: data.queryEn || undefined,

            fields: data.columns?.map(({ sourceType, field }) => ({ sourceType, field })),
            filters: [],
            hkId: data.visibility,
            hkName: getNodesByIds([data.visibility], organizationStructure)?.[0]?.name || undefined,
            descriptionDe: data.descriptionDe || undefined,
            descriptionFr: data.descriptionFr || undefined,
            descriptionIt: data.descriptionIt || undefined,
            descriptionEn: data.descriptionEn || undefined,
        }
    })

    const onSave = useCallback(async () => {
        const metadata = ReportEditorContext.metadata
        const validationFields = O(metadata).excluding('queryTestOrganizationNodes').keys()
        const validationResult = view.validateValues(view.values, validationFields)
        view.updateValidationErrors(validationResult)

        const isValid = Object.entries(validationResult).every(([_, key]) => key.length === 0)
        if (!isValid) return

        setSaveRequestStatus(createPendingStatus())
        const reportObject = buildReport(view.values)
        const [error] = await tryCatch(service.saveReport)(reportObject)

        if (error) {
            const { logNumber } = logger.error.withError(error, `Could not save the report number ${reportObject.id}`)
            const errorMessage = locale('reports.saveReportError', { logNumber })
            setSaveRequestStatus(createErrorStatus({ logNumber, error: errorMessage }))
            return
        }
        setSaveRequestStatus(createSuccessStatus())
        process.exit('save')
    })

    const onTest = useCallback(async () => {
        const metadata = ReportEditorContext.metadata
        const validationFields = O(metadata).excluding('reportName').keys()
        const validationResult = view.validateValues(view.values, validationFields)
        view.updateValidationErrors(validationResult)

        const isValid = Object.entries(validationResult).every(([_, key]) => key.length === 0)
        if (!isValid) return

        setTestRequestStatus(createPendingStatus())
        const reportObject = buildReport(view.values)
        const abortController = abortAndCreateNewController(queryTestAbortController)
        setQueryTestAbortController(abortController)
        const filterParams = {
            ...testFilterParams,
            filters: { ...testFilterParams.filters, hk_id: view.values.queryTestOrganizationNodes },
        }
        const [error, result] = await tryCatch(service.generateReport)(
            filterParams,
            abortController.signal,
            reportObject
        )
        if (error) {
            const { logNumber } = logger.info.withError(
                error,
                `Test failed either because of an unhandled error or bad query`
            )
            setTestRequestStatus(createErrorStatus({ logNumber, error: locale('reports.testReportQueryError') }))
            setQueryTestResult(error?.toString())
            return
        }
        setTestRequestStatus(createSuccessStatus())
        setQueryTestResult(JSON.stringify(result))
    })

    const onTestStop = useCallback(() => {
        queryTestAbortController.abort()
    })

    const populateForm = useMemoLast(async report => {
        view.update(fields => {
            fields.reportNameDe.value = report.nameDe
            fields.reportNameFr.value = report.nameFr
            fields.reportNameIt.value = report.nameIt
            fields.reportNameEn.value = report.nameEn
            fields.reportNumber.value = report.id
            fields.visibility.value = report.hkId
            fields.columns.value = report.fields
            fields.descriptionDe.value = report.descriptionDe
            fields.descriptionFr.value = report.descriptionFr
            fields.descriptionIt.value = report.descriptionIt
            fields.descriptionEn.value = report.descriptionEn
            fields.queryDe.value = report.sqlDe
            fields.queryFr.value = report.sqlFr
            fields.queryIt.value = report.sqlIt
            fields.queryEn.value = report.sqlEn
        })
    })

    /**
     * Disables h1 and h2 nodes. Disables nodes which use different currency then already selected ones.
     * @param organisationStructure
     * @param organisationStructureFilter
     * @return {*} organisationStructure - new organisation structure.
     */
    const getOrganisationStructureDictionary = useMemoLast((organizationStructure, organisationStructureFilter) => {
        const hierarchy = disableTopLevelOrganisationNodes(organizationStructure)
        const firstNodeId = organisationStructureFilter?.[0]
        const nodes = firstNodeId ? getNodesByIds([firstNodeId], organizationStructure) : undefined
        const currency = nodes?.[0]?.currency
        return currency ? enableNodesByCurrency(hierarchy, currency) : hierarchy
    })

    view.update((fields, errors) => {
        fields.reportNumber.value = report.id
        fields.reportNumber.visible = !!report.id
        fields.reportRequestStatus.value = reportRequestStatus
        fields.save.isLoading = saveRequestStatus?.status === STATUS_PENDING
        fields.saveRequestStatus.value = saveRequestStatus
        fields.visibility.dictionary = organizationStructure
        fields.columns.dictionary = columns
        fields.columns.isLoading = columnsRequestStatus?.status === STATUS_PENDING
        fields.columnsRequestStatus.value = columnsRequestStatus
        fields.queryTestResult.value = queryTestResult
        fields.queryTestStatus.value = testRequestStatus
        fields.queryTestOrganizationNodes.dictionary = getOrganisationStructureDictionary(
            organizationStructure,
            fields.queryTestOrganizationNodes.value
        )

        fields.queryTest.onTrigger = onTest
        fields.queryTestStop.onTrigger = onTestStop
        fields.cancel.onTrigger = onCancel
        fields.save.onTrigger = onSave

        errors.getColumnsError = getErrorStructure(locale, columnsRequestStatus?.error)
        errors.getReportError = getErrorStructure(locale, reportRequestStatus?.error)
        errors.saveReportError = getErrorStructure(locale, saveRequestStatus?.error)
    })

    return async () => {
        // ? Process Migration -> Same as in another file, why did we use 'await once' here?
        await once(async () => {
            applicationStatus.add(dictionaries)
            await dictionaries()
            process.ready()
        })
        const nextColumns = await getColumns()
        setColumns(nextColumns)
        if (id !== 'new')
            once(async () => {
                const report = (await getReport(id)) || { ...reportTemplate }
                setReport(report)
                populateForm(report)
            })
    }
})
ReportEditorProcess.label = 'Report editor'
