const TYPE_TEXT = 'TEXTBOX'
const TYPE_LIST = 'LIST'
const IMAGE = 'IMAGE'
const IMAGE_LIST = 'IMAGE_LIST'

const getElementValue = elementDto => {
    if (!elementDto) return undefined
    if (!elementDto.element) return undefined
    const type = elementDto.element.typ
    const isMultiselect = elementDto.element.multiple_select
    if (type === TYPE_LIST && isMultiselect) return elementDto.original_items?.map(item => item.id)
    if (type === TYPE_LIST && !isMultiselect) return elementDto.original_items?.at(0)?.id
    if (type === IMAGE) return elementDto.original_items?.at(0)?.image
    if (type === IMAGE_LIST) return elementDto.original_items?.map(item => item.image)
    return elementDto.inhalt
}

export const TemplateElementMetadata = element => ({
    id: element.id,
    fieldName: element.key_extern + '_key'
})

export const TemplateElement = elementInstance => ({
    value: getElementValue(elementInstance),
    elementId: TemplateElementMetadata(elementInstance?.element).id,
    fieldName: TemplateElementMetadata(elementInstance?.element).fieldName,
    metadata: TemplateElementMetadata(elementInstance?.element)
})

TemplateElement.TYPE_TEXT = TYPE_TEXT
TemplateElement.TYPE_LIST = TYPE_LIST
TemplateElement.IMAGE = IMAGE
TemplateElement.IMAGE_LIST = IMAGE_LIST
TemplateElement.CHECKBOX = 'CHECKBOX'
