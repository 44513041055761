import { ProAnalyticsJobContext } from '@views/pro_analytics/pro-analytics-job/pro-analytics-job.context'
import {
    distinct,
    formatISODate,
    haveSameElements,
    REFERENCE_COMPARE_FUNCTION,
    tryCatch,
} from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { Dictionaries } from '@modules/dictionaries/dictionaries'
import { Localization } from '@lib/i18n/localization'
import { Authorization } from '@modules/authorization/authorization'
import {
    abortAndCreateNewController,
    createErrorStatus,
    createIdleStatus,
    createPendingStatus,
    createSuccessStatus,
} from '@utils/request_statuses'
import { areDateRangesEqual } from '@views/pro_analytics/pro_analytics_filters.interface'
import { errorModal } from '@components/modules/global_notifications/global_notifications.jsx'
import JobBoosterService, { JobBoosterServiceError } from '@services/job_booster_service'
import { Process, stateOf, terminate, useCallback, useMemo, useMemoLast, useState } from '@prospective/process-router'
import { injectPluginProcesses } from '@modules/plugins/plugin_manager_process_utils.js'
import { PluginManager } from '@modules/plugins/plugin_manager'
import { calculateDeviceRatioKPIs, getPublicationDates } from '@views/pro_analytics/pro_analytics_utils.js'
import { useView } from '@lib/view_context/view_context_hooks.js'
import { getDefaultDateRange } from './pro-analytics-job.context.js'

const filterRelevantCosts = (performanceData, action) => {
    if (!performanceData) return {}
    const costs = performanceData.publicationPerformance
    const filtered = costs.filter(item => item[action] !== 0)
    return { ...performanceData, publicationPerformance: filtered }
}

const getKpiStatistics = (performanceData = { publicationPerformance: [] }) => {
    return performanceData.publicationPerformance.reduce(
        (kpis, item) => {
            kpis.views += item.views || 0
            kpis.clicks += item.clicks || 0
            kpis.applications += item.applications || 0
            kpis.deviceRatio.total += item.viewsWeb + item.viewsMobile
            kpis.deviceRatio.web += item.viewsWeb
            kpis.deviceRatio.mobile += item.viewsMobile
            kpis.deviceRatio.percentWeb = (kpis.deviceRatio.web / kpis.deviceRatio.total) * 100
            kpis.deviceRatio.percentMobile = 100 - kpis.deviceRatio.percentWeb
            return kpis
        },
        {
            views: 0,
            clicks: 0,
            applications: 0,
            deviceRatio: { total: 0, web: 0, mobile: 0 },
            pits: performanceData.pitsToday,
            jobs: distinct(performanceData.publicationPerformance, (item1, item2) => item1.stelleId === item2.stelleId)
                .length,
            costsTotal: performanceData.costsTotal,
            activePublications: performanceData.activePublications,
            dailyCosts: performanceData.dailyCosts,
        }
    )
}

const getParamsObject = (mediaFilter, dateRangeFilter, type, currency) => {
    let params = { filters: {} }
    if (type) params.typ = type
    if (currency) params.currency = currency
    if (mediaFilter !== undefined) params.filters.medium_id = mediaFilter
    if (dateRangeFilter !== undefined) {
        params.from = dateRangeFilter.from
        params.to = dateRangeFilter.to
    }

    return params
}

// TODO -> Make composable functions for MEDIAS
/**
 *
 * @param {ProcessControl} process
 * @param route
 * @param {ProAnalyticsParams} params
 * @param setParams
 * @returns {(function(): Promise<void>)|*}
 */
export const ProAnalyticsJobProcess = Process(({ process, params, setParams }) => {
    const { locale } = stateOf(Localization)
    const { isAdminUser } = stateOf(Authorization)
    const { features } = stateOf(PluginManager)
    const userDictionaries = stateOf(Dictionaries)
    const logger = Logger('ProAnalytics', CATEGORIES.MAIN)
    const view = useView(ProAnalyticsJobContext)
    const currency = isAdminUser ? undefined : userDictionaries?.organizationStructure.at(0)?.currency

    const [jobCostsStatisticsAbortController, setJobCostsStatisticsAbortController] = useState()
    const [jobCandidateJourneyAbortController, setJobCandidateJourneyAbortController] = useState()
    const [jobPerformanceStatsAbortController, setJobPerformanceStatsAbortController] = useState()

    const [costsPerMediumStatus, setCostsPerMediumStatus] = useState()
    const [costsStatistics, setCostsStatistics] = useState()
    const [costsStatisticsStatus, setCostsStatisticsStatus] = useState(createIdleStatus())
    const [candidateJourneyStatisticsStatus, setCandidateJourneyStatisticsStatus] = useState(createIdleStatus())
    const [candidateJourneyStatistics, setCandidateJourneyStatistics] = useState()
    const [performanceStatisticsStatus, setPerformanceStatisticsStatus] = useState(createIdleStatus())
    const [performanceStatisticsType, setPerformanceStatisticsType] = useState('VIEW')
    const [performanceStatistics, setPerformanceStatistics] = useState()
    const [kpiStatistics, setKpiStatistics] = useState()
    const [kpiStatisticsStatus, setKpiStatisticsStatus] = useState()

    const [jobDetailsDateSpanController, setJobDetailsDateSpanController] = useState()
    const [jobDetailsDateSpanStatus, setJobDetailsDateSpanStatus] = useState(createIdleStatus())
    const [jobDetailsDateSpan, setJobDetailsDateSpan] = useState()
    const [singleJobDatesSpan, setSingleJobDatesSpan] = useState()
    const [mediaDictionaryAbortController, setMediaDictionaryAbortController] = useState()
    const [mediaDictionaryStatus, setMediaDictionaryStatus] = useState(createIdleStatus())
    const [mediaDictionary, setMediaDictionary] = useState()

    const pluginProcesses = injectPluginProcesses(features.proAnalytics.singleJobAd, process)
    const terminatePluginProcesses = useCallback(() => pluginProcesses.forEach(process => terminate(process)))

    process.onTerminate(terminatePluginProcesses)

    const formatMediaDictionary = useMemo(dictionary =>
        dictionary
            ? dictionary.map(group => {
                  const children = group.children.map(medium => {
                      const count = medium.count || 0
                      const label = `${medium.label} (${locale('numberFormatter', count)})`
                      return { ...medium, count, label }
                  })
                  const totalCount = group.children.reduce((sum, medium) => sum + medium.count, 0)
                  return {
                      ...group,
                      count: totalCount,
                      label: `${group.label} (${locale('numberFormatter', totalCount)})`,
                      children,
                  }
              })
            : undefined
    )

    const getFilterDictionaries = useCallback(
        async (
            dictionaryParams = { from: undefined, to: undefined, hierarchyNodeId: undefined },
            locale,
            abortController
        ) => {
            const [error, dictionaries] = await tryCatch(JobBoosterService.getProAnalyticsDictionaries)(
                dictionaryParams,
                abortController.signal
            )

            if (error) {
                if (error?.type === JobBoosterServiceError.ABORT_ERROR) {
                    throw error
                } else {
                    logger.error.withError(
                        error,
                        'An error occurred while loading ProAnalytics filterDictionaries with following params',
                        dictionaryParams
                    )
                    throw new Error('An error occurred while loading ProAnalytics filterDictionaries')
                }
            }

            const mapper = medium => ({
                key: medium['media_id.keyword'],
                name: medium['medienname.keyword'],
                value: medium['media_id.keyword'],
                count: medium.count,
                label: medium['medienname.keyword'],
            })

            const identityCheck = (medium1, medium2) => medium1['media_id.keyword'] === medium2['media_id.keyword']
            const online = dictionaries.medien.online || []
            const onlinePpd = dictionaries.medien.online_PPD || []
            const privateMedia = dictionaries.medien.privat || []
            const privatePpd = dictionaries.medien.privat_PPD || []
            const crawlerPpd = dictionaries.medien.crawler_PPD || []
            const ppd = [...online, ...onlinePpd, ...privateMedia, ...privatePpd, ...crawlerPpd]
            const ppdGroup = distinct(ppd, identityCheck).map(mapper)

            const filters = {
                media: [],
            }

            if (ppdGroup.length)
                filters.media.push({
                    label: locale('media.ppd'),
                    key: 'ppd',
                    value: 'ppd',
                    count: ppdGroup.reduce((sum, entry) => sum + entry.count, 0),
                    children: ppdGroup,
                })

            return filters
        }
    )

    const getMediaDictionary = useMemoLast(
        async (dateRange = {}, jobId) => {
            const abortController = abortAndCreateNewController(mediaDictionaryAbortController)
            setMediaDictionaryAbortController(abortController)
            setMediaDictionaryStatus(createPendingStatus())

            const requestParams = {
                from: dateRange.from,
                to: dateRange?.to,
                filters: {
                    stelle_id: [jobId],
                },
            }
            const [error, dictionary] = await tryCatch(getFilterDictionaries)(requestParams, locale, abortController)

            if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                setMediaDictionaryStatus(createErrorStatus(error))
                errorModal({ title: locale('error'), content: locale('loadingDictionariesFailed') })
                return undefined
            } else {
                const mappedMediaDictionary = dictionary.media.map(group => {
                    const children = group.children.map(medium => {
                        const availableEntry = dictionary?.media
                            .find(g => g.key === group.key)
                            ?.children.find(entry => entry.key === medium.key)
                        const count = availableEntry ? availableEntry.count : 0
                        return { ...medium, count }
                    })

                    const totalCount = children.reduce((sum, medium) => sum + medium.count, 0)
                    return {
                        ...group,
                        count: totalCount,
                        children,
                    }
                })
                setMediaDictionaryStatus(createSuccessStatus())
                return formatMediaDictionary(mappedMediaDictionary)
            }
        },
        [areDateRangesEqual, REFERENCE_COMPARE_FUNCTION]
    )

    const getJobCostsStatistics = useMemoLast(
        async (id, mediaFilter = undefined, dateRangeFilter = undefined, currency) => {
            const params = getParamsObject(mediaFilter, dateRangeFilter, undefined, currency)
            const abortController = abortAndCreateNewController(jobCostsStatisticsAbortController)
            setJobCostsStatisticsAbortController(abortController)

            setCostsPerMediumStatus(createPendingStatus())
            setCostsStatisticsStatus(createPendingStatus())
            setKpiStatisticsStatus(createPendingStatus())

            const [error, statistics] = await tryCatch(JobBoosterService.getSingleJobCostStatistics)(
                id,
                params,
                abortController.signal,
            )

            if (error) {
                if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.info.withError(error, 'Could not load costs statistics')
                    setCostsPerMediumStatus(
                        createErrorStatus(
                            locale('costs per medium error', {
                                logNumber: logEntry.logNumber,
                            })
                        )
                    )
                    setCostsStatisticsStatus(
                        createErrorStatus(
                            locale('costs statistics error', {
                                logNumber: logEntry.logNumber,
                            })
                        )
                    )
                    return
                }

                return
            }

            setCostsPerMediumStatus(createSuccessStatus())
            setCostsStatisticsStatus(createSuccessStatus())
            setKpiStatisticsStatus(createSuccessStatus())
            return { ...statistics, deviceRatio: calculateDeviceRatioKPIs(statistics.publicationPerformance) }
        },
        [REFERENCE_COMPARE_FUNCTION, haveSameElements, areDateRangesEqual]
    )

    const getJobCandidateJourney = useMemoLast(
        async (id, mediaFilter = undefined, dateRangeFilter = undefined) => {
            const params = getParamsObject(mediaFilter, dateRangeFilter)
            const abortController = abortAndCreateNewController(jobCandidateJourneyAbortController)
            setJobCandidateJourneyAbortController(abortController)

            const [error, statistics] = await tryCatch(JobBoosterService.getSingleJobCandidateJourney)(
                id,
                params,
                abortController.signal
            )

            if (error) {
                if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.info.withError(error, 'Could not load candidate journey statistics')
                    setCandidateJourneyStatisticsStatus(
                        createErrorStatus(
                            locale('candidate journey error', {
                                logNumber: logEntry.logNumber,
                            })
                        )
                    )

                    return
                }

                return
            }

            setCandidateJourneyStatisticsStatus(createSuccessStatus())
            return statistics
        },
        [REFERENCE_COMPARE_FUNCTION, haveSameElements, areDateRangesEqual]
    )

    const getJobPerformanceStats = useMemoLast(
        async (id, mediaFilter, dateRangeFilter, type, currency) => {
            const params = getParamsObject(mediaFilter, dateRangeFilter, type, currency)
            setPerformanceStatisticsStatus(createPendingStatus())
            const abortController = abortAndCreateNewController(jobPerformanceStatsAbortController)
            setJobPerformanceStatsAbortController(abortController)

            const [error, statistics] = await tryCatch(JobBoosterService.getSingleJobPerformanceStats)(
                id,
                params,
                abortController.signal
            )

            if (error) {
                if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.info.withError(error, 'Could not load performance statistics')
                    setPerformanceStatisticsStatus(
                        createErrorStatus(
                            locale('performance statistics error', {
                                logNumber: logEntry.logNumber,
                            })
                        )
                    )

                    return
                }

                return
            }

            setPerformanceStatisticsStatus(createSuccessStatus())
            return statistics
        },
        [REFERENCE_COMPARE_FUNCTION, haveSameElements, areDateRangesEqual, REFERENCE_COMPARE_FUNCTION]
    )

    const getJobDates = useMemoLast(async id => {
        const abortController = abortAndCreateNewController(jobDetailsDateSpanController)
        setJobDetailsDateSpanController(abortController)
        setJobDetailsDateSpanStatus(createPendingStatus())

        const [error, jobDateRange] = await tryCatch(JobBoosterService.getSingleJobDatesSpan)(
            id,
            abortController.signal
        )

        if (error) {
            if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                const logEntry = logger.info.withError(error, 'Could not load job details date span')
                setJobDetailsDateSpanStatus(
                    createErrorStatus(
                        locale('job details error', {
                            logNumber: logEntry.logNumber,
                        })
                    )
                )
            }

            return {
                from: new Date(getDefaultDateRange().from),
                to: new Date(getDefaultDateRange().to),
            }
        }

        if (!jobDateRange?.publicationPerformance.length) {
            setJobDetailsDateSpanStatus(createSuccessStatus())
            setJobDetailsDateSpan({
                minDate: new Date(getDefaultDateRange().from),
                maxDate: new Date(getDefaultDateRange().to),
            })

            return {
                from: new Date(getDefaultDateRange().from),
                to: new Date(getDefaultDateRange().to),
            }
        }

        const dates = getPublicationDates(jobDateRange?.publicationPerformance)
        setSingleJobDatesSpan(dates)
        setJobDetailsDateSpan(dates)
        setJobDetailsDateSpanStatus(createSuccessStatus())
        return {
            from: new Date(dates.minDate),
            to: new Date(dates.maxDate),
        }
    })

    const updateDateRange = async params => {
        const jobDateRange = await getJobDates(params.jobId)
        if (!params.dateRangeFilter) return { ...params, dateRangeFilter: jobDateRange }
        return params
    }

    const extractKpiStatistics = useMemoLast(getKpiStatistics)

    const onDateRangeChange = useCallback(value => setParams({ dateRangeFilter: value }))

    const resetFilters = useCallback(fields => {
        fields.mediaFilter.value = undefined
        fields.dateRangeFilter.value = undefined
        setParams({
            mediaFilter: undefined,
            dateRangeFilter: getDefaultDateRange(),
        })
    })

    const getJobDetails = (statistics = {}) => {
        if (statistics?.publicationPerformance === undefined) return
        const jobName = statistics?.publicationPerformance[0]?.stellenTitel
        const jobId = statistics?.publicationPerformance[0]?.stelleId
        return { jobName, jobId }
    }

    view.update(( fields ) => {
        fields.costsPerMediumStatus.value = costsPerMediumStatus
        fields.costsPerMediumStatistics.value = costsStatistics
        fields.costsStatisticsStatus.value = costsStatisticsStatus
        fields.candidateJourneyStatisticsStatus.value = candidateJourneyStatisticsStatus
        fields.candidateJourneyStatistics.value = candidateJourneyStatistics
        fields.jobDetailsDatesSpanStatus.value = jobDetailsDateSpanStatus
        fields.singleJobsDatesSpan.value = singleJobDatesSpan
        fields.performanceStatisticsStatus.value = performanceStatisticsStatus
        fields.performanceStatistics.value = performanceStatistics
        fields.kpiActiveJobs.value = costsStatistics?.activeStellen
        fields.kpiActivePublications.value = costsStatistics?.activePublications
        fields.kpiPublicationsOnline.value = costsStatistics?.publicationsOnline
        fields.kpiPublicationsPrint.value = costsStatistics?.publicationsPrint
        fields.kpiCostsTotal.value = costsStatistics?.costsTotal
        fields.kpiCostsOnline.value = costsStatistics?.costsOnline
        fields.kpiCostsPrint.value = costsStatistics?.costsPrint
        fields.kpiViews.value = costsStatistics?.views
        fields.kpiClicks.value = costsStatistics?.clicks
        fields.kpiDeviceRatioWeb.value = costsStatistics?.deviceRatio.web
        fields.kpiDeviceRatioMobile.value = costsStatistics?.deviceRatio.mobile
        fields.kpiDeviceRatioPercentWeb.value = costsStatistics?.deviceRatio.percentWeb
        fields.kpiDeviceRatioPercentMobile.value = costsStatistics?.deviceRatio.percentMobile
        fields.kpiPits.value = costsStatistics?.pits
        fields.kpiStatistics.value = kpiStatistics
        fields.kpiStatisticsStatus.value = kpiStatisticsStatus
        fields.currency.value = currency
        fields.performanceStatisticsActionType.onChange = type => {
            fields.performanceStatisticsActionType.value = type
            setPerformanceStatisticsType(type)
        }
        fields.backAction.onTrigger = () => {
            process.exit()
            fields.performanceStatisticsActionType.value = 'VIEW'
        }
        fields.mediaFilterRequestStatus.value = mediaDictionaryStatus
        fields.mediaFilter.dictionary = mediaDictionary
        fields.mediaFilter.value = mediaDictionaryStatus.status === 'success' ? params.mediaFilter : undefined
        fields.mediaFilter.onChange = value => setParams({ mediaFilter: value })
        fields.dateRangeFilter.onChange = onDateRangeChange
        fields.resetFilters.onTrigger = () => resetFilters(fields)
        fields.jobDetails.value = getJobDetails(costsStatistics)
        fields.jobDetailsDatesSpan.value = jobDetailsDateSpan
        fields.dateRangeFilter.value = params.dateRangeFilter
        fields.costsByViews.value = filterRelevantCosts(costsStatistics, 'views')
        fields.costsByClicks.value = filterRelevantCosts(costsStatistics, 'clicks')
        fields.costsByApplications.value = filterRelevantCosts(costsStatistics, 'applications')
    })

    return async () => {
        const jobId = params.jobId
        const nextParams = await updateDateRange(params)
        setParams(nextParams)
        process.ready()

        setMediaDictionary(await getMediaDictionary(nextParams.dateRangeFilter, jobId))
        const [costs, candidateJourney, performance] = await Promise.all([
            getJobCostsStatistics(jobId, nextParams.mediaFilter, nextParams.dateRangeFilter, currency),
            getJobCandidateJourney(jobId, nextParams.mediaFilter, nextParams.dateRangeFilter),
            getJobPerformanceStats(
                jobId,
                nextParams.mediaFilter,
                nextParams.dateRangeFilter,
                performanceStatisticsType,
                currency
            ),
        ])

        setCostsStatistics(costs)
        setCandidateJourneyStatistics(candidateJourney)
        setPerformanceStatistics(performance)
        const kpis = extractKpiStatistics(costs)
        setKpiStatistics(kpis)
        /*pluginProcesses.forEach(pluginProcess => pluginProcess({
            jobId,
            dateRangeFilter: nextParams.dateRangeFilter,
            mediaFilter: nextParams.mediaFilter,
            kpiStatistics: kpis,
            costsStatistics: costs
        }))*/
    }
})

ProAnalyticsJobProcess.label = 'ProAnalytics Job Details'
ProAnalyticsJobProcess.paramsToQueryParams = {
    jobMedia: ({ mediaFilter }) => mediaFilter,
    jobFrom: ({ dateRangeFilter }) => dateRangeFilter?.from && formatISODate(dateRangeFilter.from),
    jobTo: ({ dateRangeFilter }) => dateRangeFilter?.to && formatISODate(dateRangeFilter.to),
}
ProAnalyticsJobProcess.queryParamsToParams = {
    mediaFilter: queryParams => queryParams.get('jobMedia'),
    dateRangeFilter: queryParams => {
        const from = queryParams.first('jobFrom')
        const to = queryParams.first('jobTo')
        if (!from || !to) return undefined
        return { from: new Date(from), to: new Date(to) }
    },
}

