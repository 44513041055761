import { BusinessObject, UnorderedListValue, Value } from './business_object.js'
import { O } from '@prospective/pms-js-utils'
import { PluginManager } from '@modules/plugins/plugin_manager.js'
import { LOGO_PLACEHOLDER } from '@structures/job.js'
import { TemplateElement } from '@structures/template_element.js'

const DEFAULT_IMAGE = {
    hash: 'fd0f18dc357e19a583337916fa5bb1dd',
    filename: 'business.jpg',
    type: 'IMAGE',
    // "image_pool_id": 1060476,
    // "url": "https://jobbooster.s3.amazonaws.com/fd0f18dc357e19a583337916fa5bb1dd",
    // "width": 941,
    // "height": 351,
    // "by_width": false,
    // "focal_point": null,
    // "language": null
}

const filterEmptyValues = object =>
    O(object)
        .filter(value => value !== undefined)
        .valueOf()

export const updateElementDto = (templateData, elementName, value) => {
    const element = templateData[elementName]
    const dto = { ...element }
    const type = element.element_info.type
    const isMultiselect = element.element_info.multi_select
    if (!isMultiselect) dto.value = value
    if (type === TemplateElement.TYPE_LIST && isMultiselect)
        dto.items = element.element_info.item_list?.filter(item => value?.includes(item.id))
    if (type === TemplateElement.TYPE_LIST && !isMultiselect)
        dto.items = element.element_info.item_list?.filter(item => value === item.id)
    if (type === TemplateElement.IMAGE_LIST)
        dto.items = value?.map(item => {
            const existing = element.element_info.item_list?.find(item => item.image.hash === value?.hash)
            return existing ? existing : { image: item }
        })
    if (type === TemplateElement.IMAGE) {
        const existing = element.element_info.item_list?.find(item => item.image.hash === value?.hash)
        dto.items = existing ? [existing] : value ? [{ image: value }] : []
        dto.value = null
    }
    return dto
}

export const getElementValue = elementDto => {
    if (!elementDto) return undefined
    if (!elementDto.element_info) return undefined
    const type = elementDto.element_info.type
    const isMultiselect = elementDto.element_info.multi_select
    if (type === TemplateElement.TYPE_LIST && isMultiselect) return elementDto.items.map(item => item.id)
    if (type === TemplateElement.TYPE_LIST && !isMultiselect) return elementDto.items.at(0)?.id
    if (type === TemplateElement.IMAGE) return elementDto.items.at(0)?.image
    if (type === TemplateElement.IMAGE_LIST) return elementDto.items.map(item => item.image)
    return elementDto.value
}

export const getElementId = elementDto => {
    if (!elementDto) return undefined
    if (!elementDto.element_info) return undefined
    const type = elementDto.element_info.type
    const isMultiselect = elementDto.element_info.multi_select
    if (type === TemplateElement.TYPE_LIST && isMultiselect) return elementDto.items.map(item => item.id)
    if (type === TemplateElement.TYPE_LIST && !isMultiselect) return elementDto.items.at(0)?.id
    if (type === TemplateElement.IMAGE) return elementDto.items.at(0)?.image
    if (type === TemplateElement.IMAGE_LIST) return elementDto.items.map(item => item.image)
    return elementDto.value
}

const PostingDescriptor = {
    jobId: Value,
    postingId: Value,
    recruiterId: Value,
    ownerId: Value,
    pitchYou: Value,
    language: Value,
    previewUrl: Value,
    layout: Value,

    jobTitle: Value,
    publicationLanguages: UnorderedListValue,
    additionalInformation: Value,
    locationCity: Value,
    locationCountry: Value,
    locationPlaceId: Value,
    locationRegion: Value,
    locationPostalCode: Value,
    locationBbox: Value,
    locationLabel: Value,
    salaryUnit: Value,
    salaryCurrency: Value,
    salaryMin: Value,
    salaryMax: Value,
    workplaceModel: Value,
    employmentType: Value,
    logo: Value,
    images: Value,
    fieldOfActivity: Value,
    workloadMin: Value,
    workloadMax: Value,
    companyVideo: Value,
    contactEmail: Value,
    pitchYouUrl: Value,
    externalJobUrl: Value,

    headlineColor: Value,
    introductionHeadline: Value,
    introductionContent: Value,
    aboutUsVisibility: Value,
    introductionVisibility: Value,
    tasksHeadline: Value,
    tasksContent: Value,
    tasksVisibility: Value,
    requirementsHeadline: Value,
    requirementsContent: Value,
    requirementsVisibility: Value,
    benefitsHeadline: Value,
    benefitsContent: Value,
    benefitsList: Value,
    benefitsVisibility: Value,
    applicationHeadline: Value,
    applicationContent: Value,
    applicationVisibility: Value,
    applicationEmail: Value,
    applicationLink: Value,
    salaryUnitLabel: Value,
    handlebarsTemplate: Value,
    layoutId: Value,
    layoutName: Value,
    layoutMessages: Value,
    layoutLanguage: Value,
    imageParameters: Value,
    logoPosition: Value,
    logoVisibility: Value,
    imageSizing: Value,
    imageVisibility: Value,
    contactNameVisibility: Value,
    contactPositionVisibility: Value,
    contactPhoneVisibility: Value,
    contactEmailVisibility: Value,
    contactHomepageVisibility: Value,
    contactImageVisibility: Value,
    applicationButtonBackground: Value,
    applicationButtonBackgroundLight: Value,
    applicationButtonBackgroundDark: Value,
    applicationButtonLabelColor: Value,
    primaryBackgroundColor: Value,
    primaryColor: Value,
    workloadVisibility: Value,
    locationVisibility: Value,
    employmentTypeVisibility: Value,
    workModelVisibility: Value,
    salaryVisibility: Value,

    // Order details:
    publicationDate: Value,
    billingReceiverId: Value,
    termsAndConditions: Value,

    originalPostingDTO: Value,
    dto: Value,
}

const fromDTO = dto => {
    const templateData = dto.template_data.template_data
    const layout = dto.layout

    const posting = {
        jobId: dto.job_id,
        postingId: dto.id,
        recruiterId: dto.recruiter_id,
        ownerId: dto.owner_id,
        pitchYou: dto.pitchyou,
        language: dto.template_data.language,
        publicationLanguages: [dto.template_data.language],
        previewUrl: dto.preview_url,

        jobTitle: getElementValue(templateData.title),
        additionalInformation: getElementValue(templateData.subtitle),
        locationCity: getElementValue(templateData['location.city']),
        locationCountry: getElementValue(templateData['location.country']),
        locationPlaceId: getElementValue(templateData['location.placeId']),
        locationRegion: getElementValue(templateData['location.region']),
        locationPostalCode: getElementValue(templateData['location.zip']),
        locationBbox: getElementValue(templateData['location.bbox']),

        salaryUnit: getElementValue(templateData['salary.unit']),
        salaryCurrency: getElementValue(templateData['salary.currency']),
        salaryMin: getElementValue(templateData['salary.min']),
        salaryMax: getElementValue(templateData['salary.max']),

        workplaceModel: getElementValue(templateData.workplace_model),
        employmentType: getElementValue(templateData.employment_type),

        logo: getElementValue(templateData.company_logo),
        images:
            layout.properties?.image?.sizing === 'crop'
                ? getElementValue(templateData.slider_images)
                : getElementValue(templateData.image)
                  ? [getElementValue(templateData.image)]
                  : [],
        introductionHeadline: getElementValue(templateData.introduction_label),
        introductionContent: getElementValue(templateData.introduction),
        tasksHeadline: getElementValue(templateData.tasks_label),
        tasksContent: getElementValue(templateData.tasks),
        requirementsHeadline: getElementValue(templateData.requirements_label),
        requirementsContent: getElementValue(templateData.requirements),
        benefitsHeadline: getElementValue(templateData.benefits_label),
        benefitsContent: getElementValue(templateData.benefits),
        benefitsList: getElementValue(templateData.benefits_list),
        applicationHeadline: getElementValue(templateData.contact_label),
        applicationContent: getElementValue(templateData.application),
        applicationEmail: getElementValue(templateData.email),
        applicationLink: getElementValue(templateData.apply_link),
        workloadMin: getElementValue(templateData['pensum.min'])
            ? parseInt(getElementValue(templateData['pensum.min']))
            : undefined,
        workloadMax: getElementValue(templateData['pensum.max'])
            ? parseInt(getElementValue(templateData['pensum.max']))
            : undefined,
        fieldOfActivity: getElementValue(templateData.field_of_activity),
        companyVideo: getElementValue(templateData.company_video) || undefined,
        pitchYouUrl: getElementValue(templateData.pitchyou),
        externalJobUrl: getElementValue(templateData.external_url),

        handlebarsTemplate: layout.html,
        layoutId: layout.id,
        layoutName: layout.name,
        layoutMessages: layout.messages,
        layoutLanguage: layout.sprache,
        headlineColor: layout.properties.color?.secondaryBackground,
        imageParameters: layout.properties.image?.params,
        logoPosition: layout.properties.logo?.position,
        logoVisibility: layout.properties.logo?.visible,
        imageSizing: layout.properties.image?.sizing,
        applicationButtonBackground: layout.properties.color?.accentBackground,
        applicationButtonBackgroundLight: layout.properties.color?.accentBackgroundLight,
        applicationButtonBackgroundDark: layout.properties.color?.accentBackgroundDark,
        applicationButtonLabelColor: layout.properties.color?.accent,
        primaryBackgroundColor: layout.properties.color?.primaryBackground,
        primaryColor: layout.properties.color?.primary,
        aboutUsVisibility: layout.properties.description?.aboutUs?.visible,
        introductionVisibility: layout.properties.description?.introduction?.visible,
        applicationVisibility: layout.properties.description?.application?.visible,
        tasksVisibility: layout.properties.description?.tasks?.visible,
        requirementsVisibility: layout.properties.description?.requirements?.visible,
        benefitsVisibility: layout.properties.description?.benefits?.visible,
        imageVisibility: layout.properties.image?.visible,
        contactNameVisibility: layout.properties?.contact?.name?.visible,
        contactPositionVisibility: layout.properties?.contact?.position?.visible,
        contactPhoneVisibility: layout.properties?.contact?.phone?.visible,
        contactEmailVisibility: layout.properties?.contact?.email?.visible,
        contactHomepageVisibility: layout.properties?.contact?.homepage?.visible,
        contactImageVisibility: layout.properties?.contact?.image?.visible,
        workloadVisibility: layout.properties.workload?.visible,
        locationVisibility: layout.properties.location?.visible,
        employmentTypeVisibility: layout.properties.employmentType?.visible,
        workModelVisibility: layout.properties.workmodel?.visible,
        salaryVisibility: layout.properties.salary?.visible,
        dto: dto,
    }
    return Posting(filterEmptyValues(posting))
}

const TemplateData = templateData => {
    const result = {}
    const set = (elementName, value) => {
        if (templateData[elementName]) result[elementName] = updateElementDto(templateData, elementName, value)
    }
    const getDTO = () => ({
        ...templateData,
        ...result,
    })
    return { set, getDTO }
}

const getDto = posting => {
    const dto = {
        ...posting.dto,
        template_data: {
            ...posting.dto?.template_data,
            template_data: {
                ...posting.dto?.template_data.template_data,
            },
        },
        layout: {
            ...posting.dto?.layout,
            properties: {
                ...posting.dto?.layout?.properties,
                logo: { ...posting.dto?.layout?.properties?.logo },
                image: { ...posting.dto?.layout?.properties?.image },
                color: { ...posting.dto?.layout?.properties?.color },
                description: {
                    ...posting.dto?.layout?.properties?.description,
                    aboutUs: { ...posting.dto?.layout?.properties?.description?.aboutUs },
                    introduction: { ...posting.dto?.layout?.properties?.description?.introduction },
                    application: { ...posting.dto?.layout?.properties?.description?.application },
                    tasks: { ...posting.dto?.layout?.properties?.description?.tasks },
                    requirements: { ...posting.dto?.layout?.properties?.description?.requirements },
                    benefits: { ...posting.dto?.layout?.properties?.description?.benefits },
                },
                contact: {
                    ...posting.dto?.layout?.properties?.contact,
                    name: { ...posting.dto?.layout?.properties?.contact?.name },
                    position: { ...posting.dto?.layout?.properties?.contact?.position },
                    phone: { ...posting.dto?.layout?.properties?.contact?.phone },
                    email: { ...posting.dto?.layout?.properties?.contact?.email },
                    homepage: { ...posting.dto?.layout?.properties?.contact?.homepage },
                    image: { ...posting.dto?.layout?.properties?.contact?.image },
                },
                workload: { ...posting.dto?.layout?.properties?.workload },
                location: { ...posting.dto?.layout?.properties?.location },
                employmentType: { ...posting.dto?.layout?.properties?.employmentType },
                workmodel: { ...posting.dto?.layout?.properties?.workmodel },
                salary: { ...posting.dto?.layout?.properties?.salary },
            },
        },
    }

    dto.pitchyou = posting.pitchYou
    if (posting.jobId) dto.job_id = posting.jobId
    if (posting.postingId) dto.id = posting.postingId
    if (posting.recruiterId) dto.recruiter_id = posting.recruiterId
    if (posting.ownerId) dto.owner_id = posting.ownerId
    if (posting.language) dto.template_data.language = posting.language

    const templateData = TemplateData(dto.template_data.template_data)
    if (posting.jobTitle) templateData.set('title', posting.jobTitle)
    templateData.set('subtitle', posting.additionalInformation)
    if (posting.locationCity) templateData.set('location.city', posting.locationCity)
    if (posting.locationCountry) templateData.set('location.country', posting.locationCountry)
    if (posting.locationPlaceId) templateData.set('location.placeId', posting.locationPlaceId)
    if (posting.locationRegion) templateData.set('location.region', posting.locationRegion)
    templateData.set('location.zip', posting.locationPostalCode)
    if (posting.locationBbox) templateData.set('location.bbox', posting.locationBbox)
    if (posting.salaryUnit) templateData.set('salary.unit', posting.salaryUnit)
    if (posting.salaryCurrency) templateData.set('salary.currency', posting.salaryCurrency)
    if (posting.salaryMin) templateData.set('salary.min', posting.salaryMin)
    if (posting.salaryMax) templateData.set('salary.max', posting.salaryMax)
    if (posting.workplaceModel) templateData.set('workplace_model', posting.workplaceModel)
    if (posting.employmentType) templateData.set('employment_type', posting.employmentType)

    if (posting.logo) templateData.set('company_logo', posting.logo)
    if (posting.images && posting.imageSizing === 'crop') {
        templateData.set('image', null)
        templateData.set('slider_images', posting.images)
    } else if (posting.images) {
        templateData.set('image', posting.images.at(0))
        templateData.set('slider_images', [])
    }
    if (posting.imageVisibility === false) {
        templateData.set('image', null)
        templateData.set('slider_images', [])
    }
    if (posting.introductionHeadline) templateData.set('introduction_label', posting.introductionHeadline)
    if (posting.introductionContent) templateData.set('introduction', posting.introductionContent)
    if (posting.tasksHeadline) templateData.set('tasks_label', posting.tasksHeadline)
    if (posting.tasksContent) templateData.set('tasks', posting.tasksContent)
    if (posting.requirementsHeadline) templateData.set('requirements_label', posting.requirementsHeadline)
    if (posting.requirementsContent) templateData.set('requirements', posting.requirementsContent)
    if (posting.benefitsHeadline) templateData.set('benefits_label', posting.benefitsHeadline)
    if (posting.benefitsContent) templateData.set('benefits', posting.benefitsContent)
    if (posting.benefitsList) templateData.set('benefits_list', posting.benefitsList)
    if (posting.applicationHeadline) templateData.set('contact_label', posting.applicationHeadline)
    if (posting.applicationContent) templateData.set('application', posting.applicationContent)
    if (posting.applicationEmail) templateData.set('email', posting.applicationEmail)
    if (posting.applicationLink) templateData.set('apply_link', posting.applicationLink)
    if (posting.workloadMin) templateData.set('pensum.min', posting.workloadMin.toFixed())
    if (posting.workloadMax) templateData.set('pensum.max', posting.workloadMax.toFixed())
    if (posting.fieldOfActivity) templateData.set('field_of_activity', posting.fieldOfActivity)
    if (posting.companyVideo) templateData.set('company_video', posting.companyVideo)
    if (posting.pitchYouUrl) templateData.set('pitchyou', posting.pitchYouUrl)
    if (posting.externalJobUrl) templateData.set('external_url', posting.externalJobUrl)

    if (posting.logoPosition) dto.layout.properties.logo.position = posting.logoPosition
    if (posting.imageSizing) dto.layout.properties.image.sizing = posting.imageSizing
    if (posting.imageParameters) dto.layout.properties.image.params = posting.imageParameters
    if (posting.headlineColor) dto.layout.properties.color.secondaryBackground = posting.headlineColor
    if (posting.applicationButtonBackground)
        dto.layout.properties.color.accentBackground = posting.applicationButtonBackground
    if (posting.applicationButtonBackgroundLight)
        dto.layout.properties.color.accentBackgroundLight = posting.applicationButtonBackgroundLight
    if (posting.applicationButtonBackgroundDark)
        dto.layout.properties.color.accentBackgroundDark = posting.applicationButtonBackgroundDark
    if (posting.applicationButtonLabelColor) dto.layout.properties.color.accent = posting.applicationButtonLabelColor
    if (posting.primaryBackgroundColor) dto.layout.properties.color.primaryBackground = posting.primaryBackgroundColor
    if (posting.primaryColor) dto.layout.properties.color.primary = posting.primaryColor

    dto.layout.properties.logo.visible = posting.logoVisibility
    dto.layout.properties.image.visible = posting.imageVisibility
    dto.layout.properties.description.aboutUs.visible = posting.aboutUsVisibility
    dto.layout.properties.description.introduction.visible = posting.introductionVisibility
    dto.layout.properties.description.application.visible = posting.applicationVisibility
    dto.layout.properties.description.tasks.visible = posting.tasksVisibility
    dto.layout.properties.description.requirements.visible = posting.requirementsVisibility
    dto.layout.properties.description.benefits.visible = posting.benefitsVisibility
    dto.layout.properties.contact.name.visible = posting.contactNameVisibility
    dto.layout.properties.contact.position.visible = posting.contactPositionVisibility
    dto.layout.properties.contact.phone.visible = posting.contactPhoneVisibility
    dto.layout.properties.contact.email.visible = posting.contactEmailVisibility
    dto.layout.properties.contact.homepage.visible = posting.contactHomepageVisibility
    dto.layout.properties.contact.image.visible = posting.contactImageVisibility
    dto.layout.properties.workload.visible = posting.workloadVisibility
    dto.layout.properties.location.visible = posting.locationVisibility
    dto.layout.properties.employmentType.visible = posting.employmentTypeVisibility
    dto.layout.properties.workmodel.visible = posting.workModelVisibility
    dto.layout.properties.salary.visible = posting.salaryVisibility

    const newTemplateData = templateData.getDTO()
    Object.assign(dto.template_data.template_data, newTemplateData)
    return dto
}

/**
 *
 * @param {PostingType} posting
 * @param {JobAdLanguage} language
 * @param {Profile[]} applicationContacts
 * @param {object} attributes
 * @returns {{benefits: {text: (boolean|*), title: (boolean|*), items: (boolean|*)}, image: (*|undefined), aboutUs: {text: *, title: *}, images: ({headerImages}|undefined), requirements: {text: (boolean|*), title: (boolean|*)}, workload, title: string, salary: {unit: *, min: *, max: *, currency: *}, external: {url: *}, application: {text: (boolean|*), title: (boolean|*)}, subtitle: string, contact: {image: {hash: *, url: *}, firstname: *, address: {zip: *, country: *, city: *, street: *, companyName: *}, phone: *, salutation: *, position: *, title: *, email: *, lastname: *, homepage: *}, logo: (*|{hash: string, url: string}), attributes: {employmentType: *, pitchyou: (string|null)}, location: ((*&{city: (`${*} ${*}`|` ${*}`)})|{zip: string, country: string, city: string, street: string, region: string}), workmodel: *, company: (*&{zip: string, country: string, city: string, street: string, name: string, addressExtra: string, homepage: string}), introduction: {text: (boolean|*), title: (boolean|*)}, tasks: {text: (boolean|*), title: (boolean|*)}}}
 */
const toTemplateData = (posting, language, applicationContacts = [], attributes = {}) => {
    const recruiter = applicationContacts.find(contactPerson => contactPerson.id === posting.recruiterId)
    const priceFormatter = PluginManager?.features?.app?.templatePriceFormatter.inject
    const employmentType = attributes.employment_type?.find(item => item.id === posting.employmentType)?.label
    const salaryUnit = attributes['salary.unit'].find(item => item.id === posting.salaryUnit)?.value
    const salaryCurrency = attributes['salary.currency'].find(item => item.id === posting.salaryCurrency)?.label
    const workModel = attributes.workplace_model.find(item => item.id === posting.workplaceModel)?.label

    return {
        title: posting.jobTitle || '',
        subtitle: posting.additionalInformation || '',
        attributes: {
            employmentType: employmentType,
            pitchyou: posting.pitchYou ? 'fakePitchYouLink' : null,
        },
        location: {
            zip: posting.locationPostalCode,
            country: posting.locationCountry,
            region: posting.locationRegion,
            city: `${posting.locationPostalCode || ''} ${posting.locationCity}`,
        },
        salary: {
            unit: salaryUnit,
            min: priceFormatter(posting.salaryMin),
            max: priceFormatter(posting.salaryMax),
            currency: salaryCurrency,
        },
        logo: posting.logo?.url ? { ...posting.logo } : LOGO_PLACEHOLDER,
        images: posting.imageSizing === 'crop' ? { headerImages: posting.images } : undefined,
        image: posting.imageSizing !== 'crop' ? posting.images?.at(0) : undefined,
        workload: { min: posting.workloadMin, max: posting.workloadMax },
        workmodel: workModel,
        introduction: { title: posting.introductionHeadline, text: posting.introductionContent },
        requirements: { title: posting.requirementsHeadline, text: posting.requirementsContent },
        tasks: { title: posting.tasksHeadline, text: posting.tasksContent },
        benefits: {
            title: posting.benefitsHeadline,
            text: posting.benefitsContent,
            items: posting.benefitsList?.map(value => attributes.benefits_list.find(item => item.id === value)),
        },
        aboutUs: { title: posting.companyProfileHeadline, text: posting.companyProfileContent },
        application: { title: posting.applicationHeadline, text: posting.applicationContent },
        contact: {
            title: recruiter?.jobTitle?.[language],
            salutation: recruiter?.salutation?.[language],
            firstname: recruiter?.firstName?.[language],
            lastname: recruiter?.lastName?.[language],
            position: recruiter?.position?.[language],
            phone: recruiter?.phone,
            email: recruiter?.email,
            homepage: recruiter?.customAddress?.web,
            image: {
                hash: recruiter?.image?.hash,
                url: recruiter?.image?.url,
            },
            address: {
                companyName: recruiter?.customAddress?.company?.[language],
                street: recruiter?.customAddress?.street?.[language],
                city: recruiter?.customAddress?.place?.[language],
                zip: recruiter?.customAddress?.postalCode,
                country: recruiter?.customAddress?.country,
            },
        },
        // TODO: What is company field for?
        company: {
            name: '',
            street: '',
            addressExtra: '',
            zip: '',
            city: '',
            country: '',
            homepage: '',
            ...posting.company,
        },
        external: { url: posting.externaJobUrl },
    }
}

const toLayoutProperties = posting => {
    return {
        logo: {
            visible: posting.logoVisibility,
            position: posting.logoPosition,
        },
        image: {
            visible: posting.imageVisibility,
            sizing: posting.imageSizing,
            params: posting.imageParameters,
        },
        color: {
            accent: posting.applicationButtonLabelColor,
            accentBackground: posting.applicationButtonBackground,
            accentBackgroundLight: posting.applicationButtonBackgroundLight,
            accentBackgroundDark: posting.applicationButtonBackgroundDark,
            primaryBackground: posting.primaryBackgroundColor,
            primary: posting.primaryColor,
            secondaryBackground: posting.headlineColor,
        },
        workload: {
            visible: posting.workloadVisibility,
        },
        employmentType: {
            visible: posting.employmentTypeVisibility,
        },
        workmodel: {
            visible: posting.workModelVisibility,
        },
        salary: {
            visible: posting.salaryVisibility,
        },
        location: {
            visible: posting.locationVisibility,
        },
        contact: {
            name: {
                visible: posting.contactNameVisibility,
            },
            position: {
                visible: posting.contactPositionVisibility,
            },
            phone: {
                visible: posting.contactPhoneVisibility,
            },
            email: {
                visible: posting.contactEmailVisibility,
            },
            homepage: {
                visible: posting.contactHomepageVisibility,
            },
            image: {
                visible: posting.contactImageVisibility,
            },
        },
        description: {
            aboutUs: { visible: posting.aboutUsVisibility },
            application: { visible: posting.applicationVisibility },
            benefits: { visible: posting.benefitsVisibility },
            introduction: { visible: posting.introductionVisibility },
            requirements: { visible: posting.requirementsVisibility },
            tasks: { visible: posting.tasksVisibility },
        },
    }
}

const PostingExtension = posting => ({
    toDTO: () => getDto(posting),
    toTemplateData: (language, applicationContacts, attributes) =>
        toTemplateData(posting, language, applicationContacts, attributes),
    getLayoutProperties: () => toLayoutProperties(posting),
    publicationLanguage: posting.publicationLanguages?.at(0),
})

export const Posting = BusinessObject(PostingDescriptor).extend(PostingExtension)
Posting.fromDTO = fromDTO
Posting.DEFAULT_IMAGE = DEFAULT_IMAGE
