import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { ProAnalyticsReportContext } from './pro_analytics_report.context'
import { formatISODate, haveSameElements, REFERENCE_COMPARE_FUNCTION, tryCatch } from '@prospective/pms-js-utils'
import { warningModal } from '@components/modules/global_notifications/global_notifications.jsx'
import { Localization } from '@lib/i18n/localization'
import { getDefaultDateRange } from '@views/pro_analytics/pro_analytics.context'
import { firstSelectableDate, lastSelectableDate, validateDateRange } from '@views/pro_analytics/pro_analytics_utils'
import { CustomerConfiguration } from '@modules/customer_config/customer_config.process'
import { formatSearchSuggestion, getReports, getXlsReport } from './reports_utilities/reports_utilities'
import { ProAnalyticsFilters } from '@views/pro_analytics/pro_analytics_filters'
import { ProAnalyticsFiltersInterface } from '@views/pro_analytics/pro_analytics_filters.interface'
import { ProAnalyticsSearch } from '@views/pro_analytics/pro_analytics_search'
import { Dictionaries } from '@modules/dictionaries/dictionaries'
import { UserSession } from '@login/user-session.js'
import { Authorization } from '@modules/authorization/authorization'
import { includeChildNodes } from '@modules/dictionaries/organization-hierarchy'
import { ProAnalyticsOrganizationFilter } from '@views/pro_analytics/pro_analytics_organization_filter'
import {
    abortAndCreateNewController,
    createErrorStatus,
    createPendingStatus,
    createSuccessStatus,
} from '@utils/request_statuses'
import JobBoosterService, { JobBoosterServiceError } from '@services/job_booster_service'
import { Process, stateOf, useCallback, useEffect, useMemo, useMemoLast, useState } from '@prospective/process-router'
import { useView } from '@lib/view_context/view_context_hooks.js'

const dateRangeValidation = (params, defaultDateRange) => {
    const validDateRange = validateDateRange(params.dateRangeFilter, defaultDateRange)
    let parameters = params
    if (validDateRange !== params.dateRangeFilter) {
        warningModal(
            Localization.locale('proAnalytics.dateRange.autoCorrectionWarning', {
                start: firstSelectableDate,
                end: lastSelectableDate,
            })
        )
        parameters = { ...params, dateRangeFilter: validDateRange }
    }
    return parameters
}

/**
 *
 * @param process
 * @param route
 * @param {ProAnalyticsParams} params
 * @param setParams
 * @returns {(function(): Promise<void>)|*}
 */
export const ProAnalyticsReport = Process(({ process, params, setParams }) => {
    const logger = Logger('ProAnalytics', CATEGORIES.MAIN)
    const { locale, languageCode } = stateOf(Localization)
    const { proAnalyticsPermissions, isAdminUser } = stateOf(Authorization)
    const { user } = UserSession
    const userDictionaries = stateOf(Dictionaries)
    const view = useView(ProAnalyticsReportContext)

    const isPMSAgency = user?.njc?.mandate?.agency === 'PMS'
    const pmsFiltersVisible = isPMSAgency || isAdminUser

    const [allReportsAbortController, setAllReportsAbortController] = useState()
    const [customerConfigRequestStatus, setCustomerConfigRequestStatus] = useState()
    const [queriedReportsStatus, setQueriedReportsStatus] = useState()
    const [xlsDownloadLoading, setXlsDownloadLoading] = useState(null)
    const [queriedReports, setQueriedReports] = useState()
    const [reportsStatus, setReportsStatus] = useState()
    const [currentReport, setCurrentReport] = useState()
    const [noReportId, setNoReportId] = useState(false)
    const [reportsList, setReportsList] = useState()

    const filters = ProAnalyticsFilters({
        params,
        setParams,
        view,
    })

    const search = ProAnalyticsSearch({
        params,
        setParams,
        view,
        suggestionsParser: formatSearchSuggestion,
    })
    const proAnalyticsOrganizationFilter = ProAnalyticsOrganizationFilter({
        params,
        setParams,
    })

    // Reports List
    const getAllReports = useMemo(async () => {
        const abortController = abortAndCreateNewController(allReportsAbortController)

        setAllReportsAbortController(abortController)
        setQueriedReportsStatus(createPendingStatus())
        setReportsStatus(createPendingStatus())

        const [error, reports] = await tryCatch(JobBoosterService.getAllReports)(abortController.signal)

        if (error) {
            if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                const logEntry = logger.info.withError(error, 'Could not load reports')
                setReportsStatus(
                    createErrorStatus(
                        locale('proAnalytics.reports.error', {
                            logNumber: logEntry.logNumber,
                        })
                    )
                )
                return
            }

            return
        }

        setReportsStatus(createSuccessStatus())
        setReportsList(reports)
        return reports
    })

    // Queried reports
    const getAllQueryReports = useMemoLast(
        async (filterParams, reportId, reports, organizationStructure, currency, languageCode) => {
            const organizationFilterState = await proAnalyticsOrganizationFilter.update()
            const organizationFilterValid = organizationFilterState.isOrganisationStructureFilterValid
            if (!organizationFilterValid) return
            const abortController = abortAndCreateNewController(allReportsAbortController)
            setQueriedReportsStatus(createPendingStatus())

            const requestParams = ProAnalyticsFiltersInterface(filterParams).getRequestParameters(
                organizationStructure,
                locale
            )
            requestParams.currency = currency
            requestParams.language = languageCode

            const report = reports.find(report => report.id === +reportId)

            if (!report || !reportId) {
                setNoReportId(true)
                return
            }

            const [error, data] = await tryCatch(JobBoosterService.generateReport)(
                requestParams,
                abortController.signal,
                report
            )

            if (error) {
                if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
                    const logEntry = logger.info.withError(error, 'Could not load reports')
                    setQueriedReportsStatus(
                        createErrorStatus(
                            locale('proAnalytics.queriedReports.error', {
                                logNumber: logEntry.logNumber,
                            })
                        )
                    )
                    return
                }

                return
            }

            setQueriedReportsStatus(createSuccessStatus())
            setQueriedReports(data)
        },
        [
            ProAnalyticsFiltersInterface.areFiltersEqual,
            REFERENCE_COMPARE_FUNCTION,
            REFERENCE_COMPARE_FUNCTION,
            REFERENCE_COMPARE_FUNCTION,
            REFERENCE_COMPARE_FUNCTION,
        ]
    )

    useEffect(async () => {
        // applicationStatus.add(login).add(authorization).add(dictionaries)
        // await login()
        // await authorization()
        // await dictionaries()

        const parameters = dateRangeValidation(params, getDefaultDateRange())
        delete parameters.textFilter
        setParams(parameters)
        process.ready()
    }, [])

    const onReportPreviewClick = useCallback(reportId => {
        setParams({ ...params, reportId })
    })

    const handleXlsDownload = async (reportId, reportName) => {
        setXlsDownloadLoading({ id: reportId, loading: true })

        const [error, data] = await getReports(reportsList, reportId, params)

        if (error) logger.error.withError(error, 'Export to Excel file failed')
        getXlsReport(data, reportName, params.dateRangeFilter).catch(error => {
            logger.error.withError(error, 'Failed to generate xls report')
        })
        setXlsDownloadLoading(null)
    }

    const backActionHandler = useCallback(() => {
        process.exit()
    })

    const getCustomerConfig = useMemo(async nodeIds => {
        setCustomerConfigRequestStatus(createPendingStatus())
        const [error] = await tryCatch(CustomerConfiguration)({ nodeIds })

        if (error) setCustomerConfigRequestStatus(createErrorStatus(error))
        else setCustomerConfigRequestStatus(createSuccessStatus())
    }, haveSameElements)

    view.update((fields, errors) => {
        search.viewUpdate(fields)
        proAnalyticsOrganizationFilter.viewUpdate(fields, errors)
        filters.viewUpdate(fields, errors)

        fields.pmsFilters.visible = pmsFiltersVisible
        fields.currentReport.value = currentReport
        fields.reports.value = reportsList
        fields.queriedReports.value = queriedReports
        fields.reportsStatus.value = reportsStatus
        fields.queriedReportsStatus.value = queriedReportsStatus
        fields.noReportId.value = noReportId
        fields.reportXls.onTrigger = handleXlsDownload
        fields.reportsLink.onTrigger = onReportPreviewClick
        fields.reportXlsLoading.value = xlsDownloadLoading
        fields.backAction.onTrigger = backActionHandler
        fields.customerConfigStatus.value = customerConfigRequestStatus

        errors.insufficientPermissions =
            !proAnalyticsPermissions.reports.widget.permissions.read &&
            customerConfigRequestStatus !== undefined &&
            customerConfigRequestStatus?.status !== 'pending'
                ? locale('proAnalytics.reports.insufficientPermissions')
                : undefined
    })

    return async () => {
        if (!proAnalyticsPermissions.reports.widget.permissions.read) return
        const organizationFilterState = await proAnalyticsOrganizationFilter.update()
        const organizationFilterValid = organizationFilterState.isOrganisationStructureFilterValid
        const organizationStructure = userDictionaries.organizationStructure
        const currency = organizationFilterState.currency

        if (organizationFilterValid) {
            let selectedNodes = []

            if (organizationFilterState.selectedOrganizationNodes.length)
                selectedNodes = organizationFilterState.selectedOrganizationNodes
            else if (organizationStructure[0])
                selectedNodes = includeChildNodes([organizationStructure[0].id], organizationStructure)

            getCustomerConfig(selectedNodes)
        }

        const parameters = dateRangeValidation(params, getDefaultDateRange())
        const reportId = parameters.reportId || null

        setCurrentReport(reportId)
        setParams(parameters)

        const [filtersState] = await Promise.all([filters.update(), search.update()])

        const reports = await getAllReports()
        if (
            !proAnalyticsOrganizationFilter.selectedOrganizationNodes ||
            proAnalyticsOrganizationFilter.selectedOrganizationNodes.length === 0
        ) {
            return
        }
        getAllQueryReports(
            filtersState.filters,
            reportId,
            reports,
            proAnalyticsOrganizationFilter.selectedOrganizationNodes,
            currency,
            languageCode
        )
    }
})

ProAnalyticsReport.label = 'ProAnalytics Reports'
ProAnalyticsReport.paramsToQueryParams = {
    search: ({ textFilter }) => textFilter || undefined,
    jobTitle: ({ internalTitle }) => (internalTitle ? internalTitle : undefined),
    externalJobTitle: ({ externalTitle }) => (externalTitle ? externalTitle : undefined),
    posting: ({ postingId }) => postingId,
    publication: ({ publicationId }) => publicationId,
    order: ({ orderId }) => orderId,
    recruiter: ({ recruiterFilter }) => recruiterFilter,
    recruiterSearch: ({ recruiterKeyword }) => recruiterKeyword,
    media: ({ mediaFilter }) => mediaFilter,
    field_of_activity: ({ fieldOfActivityFilter }) => fieldOfActivityFilter,
    industry: ({ industryFilter }) => industryFilter,
    ats: ({ atsFilter }) => atsFilter,
    node: ({ organizationStructureFilter }) => organizationStructureFilter,
    from: ({ dateRangeFilter }) => dateRangeFilter?.from && formatISODate(dateRangeFilter.from),
    to: ({ dateRangeFilter }) => dateRangeFilter?.to && formatISODate(dateRangeFilter.to),
}
ProAnalyticsReport.queryParamsToParams = {
    textFilter: queryParams => queryParams.first('search'),
    internalTitle: queryParams => queryParams.first('jobTitle'),
    externalTitle: queryParams => queryParams.first('externalJobTitle'),
    postingId: queryParams => queryParams.first('posting'),
    publicationId: queryParams => queryParams.first('publication'),
    orderId: queryParams => queryParams.first('order'),
    recruiterKeyword: queryParams => queryParams.get('recruiterSearch'),
    recruiterFilter: queryParams => queryParams.get('recruiter').map(value => parseInt(value)),
    mediaFilter: queryParams => queryParams.get('media'),
    fieldOfActivityFilter: queryParams => queryParams.get('field_of_activity'),
    industryFilter: queryParams => queryParams.get('industry'),
    atsFilter: queryParams => queryParams.get('ats'),
    organizationStructureFilter: queryParams => queryParams.get('node').map(value => parseInt(value)),
    dateRangeFilter: queryParams => {
        const from = queryParams.first('from')
        const to = queryParams.first('to')
        if (!from || !to) return getDefaultDateRange()
        return { from: new Date(from), to: new Date(to) }
    },
}
