import { ReportListContext } from '@views/settings/reports/report_list.context.js'
import {
    createErrorStatus,
    createIdleStatus,
    createPendingStatus,
    createSuccessStatus,
    getErrorStructure,
    STATUS_ERROR,
    STATUS_PENDING,
    STATUS_SUCCESS,
} from '@utils/request_statuses'
import { tryCatch } from '@prospective/pms-js-utils'
import { Localization } from '@lib/i18n/localization'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { ReportEditorProcess } from './report_editor.process'
import { Process, stateOf, useCallback, useChildProcess, useOnce, useState } from '@prospective/process-router'
import { useView } from '@lib/view_context/view_context_hooks'
import JobBoosterService from '@services/job_booster_service'
import { useRemoteDataStream } from '@utils/process_hooks.js'
import { ModuleController } from '@modules/module_controller.js'
import { Authorization } from '@modules/authorization/authorization.js'

const logger = Logger('ReportListProcess', CATEGORIES.MAIN)
export const ReportListProcess = Process(({ process }) => {
    const once = useOnce()
    const { locale } = stateOf(Localization)
    const view = useView(ReportListContext)
    const [reports, setReports] = useState()
    const [reportsRequestStatus, setReportsRequestStatus] = useState()
    const [deleteRequestStatus, setDeleteRequestStatus] = useState(createIdleStatus({}))
    const [reportEditor, ReportEditor] = useChildProcess(ReportEditorProcess)
    stateOf(Authorization)
    const [getProAnalyticsPermissions, getProAnalyticsPermissionsState] = useRemoteDataStream(
        ModuleController.getProAnalyticsPermissions
    )
    const [login, loginState] = useRemoteDataStream(ModuleController.login)

    reportEditor.then(async result => {
        if (result === 'save') {
            const reports = await getReports()
            setReports(reports)
        }
    })

    const getReports = async () => {
        setReportsRequestStatus(createPendingStatus())
        const [error, reports] = await tryCatch(JobBoosterService.getAllReports)()
        if (error) {
            const { logNumber } = logger.error.withError(error, "Couldn't load report list")
            const errorMessage = locale('reports.getReportListError', { logNumber })
            setReportsRequestStatus(createErrorStatus({ logNumber, error: errorMessage }))
            return []
        }
        setReportsRequestStatus(createSuccessStatus())
        return reports.map(item => ({ ...item, key: item.id }))
    }

    const createReport = useCallback(() => process.child(ReportEditorProcess({ id: 'new' })))
    const editReport = useCallback(id => process.child(ReportEditorProcess({ id })))

    const setDeleteStatusPending = useCallback(id =>
        setDeleteRequestStatus(createPendingStatus(0, { ...deleteRequestStatus.details, [id]: STATUS_PENDING }))
    )

    const setDeleteStatusError = useCallback((id, error) => {
        const { logNumber } = logger.error.withError(error, `Couldn't delete report ${id}`)
        const errorMessage = locale('reports.deleteReportError', { logNumber, reportName: id })
        setDeleteRequestStatus(
            createErrorStatus(
                {
                    logNumber,
                    error: errorMessage,
                },
                { ...deleteRequestStatus.details, [id]: STATUS_ERROR }
            )
        )
    })

    const setDeleteStatusSuccess = useCallback(id =>
        setDeleteRequestStatus(createSuccessStatus({ ...deleteRequestStatus.details, [id]: STATUS_SUCCESS }))
    )

    const deleteReport = useCallback(async id => {
        setDeleteStatusPending(id)
        const [error] = await tryCatch(JobBoosterService.deleteReport)(id)
        if (error) {
            setDeleteStatusError(id, error)
            const nextReports = await getReports()
            setReports(nextReports)
            return []
        }
        setDeleteStatusSuccess(id)
        const nextReports = await getReports()
        setReports(nextReports)
    })

    view.update((fields, errors) => {
        fields.reports.value = reports
        fields.reportsRequestStatus.value = reportsRequestStatus
        fields.reportDeleteStatus.value = deleteRequestStatus

        fields.newReport.onTrigger = createReport
        fields.editReport.onTrigger = editReport
        fields.deleteReport.onTrigger = deleteReport

        errors.applyError = getErrorStructure(locale, reportsRequestStatus?.error)
        errors.deleteError = getErrorStructure(locale, deleteRequestStatus?.error)
    })

    return async () => {
        await login()
        process.ready()
        const permissions = await getProAnalyticsPermissions()

        if (permissions.reports.management.permissions.read) {
            await once(async () => {
                const nextReports = await getReports()
                setReports(nextReports)
            })
        }
    }
})

ReportListProcess.label = 'Report list'
