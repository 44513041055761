import { CATEGORIES, Logger } from '@modules/logging/logger'
import { Process, stateOf, useMemoLast, useState, useCallback } from '@prospective/process-router'
import { Localization } from '@lib/i18n/localization'
import { InvoicesContext } from '@views/settings/invoices/invoices.context'
import { tryCatch } from '@prospective/pms-js-utils'
import {
    createErrorStatus,
    createIdleStatus,
    createPendingStatus,
    createSuccessStatus,
    getErrorStructure,
} from '@utils/request_statuses'
import { useView } from '@lib/view_context/view_context_hooks'
import { errorNotification } from '@components/modules/global_notifications/global_notifications'
import { useRemoteDataStream } from '@utils/process_hooks.js'
import { ModuleController } from '@modules/module_controller.js'
import { HierarchySelection } from '@modules/hierarchy/hierarchy.js'
import JobBoosterService, { JobBoosterServiceError } from '@services/job_booster_service'
import { RemoteData } from '@lib/remote_data/remote-data.js'

const prepareInvoicePDF = (invoicePdf, pdfName) => {
    const url = window.URL.createObjectURL(invoicePdf)
    const a = document.createElement('a')
    a.href = url
    a.download = `${pdfName}.pdf`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
}

export const sortBillingDates = (a, b) => {
    if (a.billing_date === null && b.billing_date !== null) return 1
    else if (a.billing_date !== null && b.billing_date === null) return -1
    else if (a.billing_date === null && b.billing_date === null) return 0
    else return new Date(b.billing_date) - new Date(a.billing_date)
}

const logger = Logger('InvoicesProcess', CATEGORIES.MAIN)
export const InvoicesProcess = Process(({ process }) => {
    const { locale } = stateOf(Localization)
    const view = useView(InvoicesContext)
    stateOf(HierarchySelection)

    const [invoicesRequestStatus, setInvoicesRequestStatus] = useState(createPendingStatus())
    const [pdfDownloadRequestStatus, setPdfDownloadRequestStatus] = useState(createIdleStatus())
    const [invoices, setInvoices] = useState()
    const [getHierarchyNode, getHierarchyNodeState] = useRemoteDataStream(ModuleController.getHierarchyNode)
    const [getPermissions, permissionsState] = useRemoteDataStream(ModuleController.getPermissions, RemoteData.queued())

    const getInvoices = useMemoLast(async companyId => {
        setInvoicesRequestStatus(createPendingStatus())
        const [error, invoices] = await tryCatch(JobBoosterService.getInvoices)(companyId)

        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            const { logNumber } = logger.error.withError(error, 'Could not load invoices.')
            setInvoicesRequestStatus(
                createErrorStatus({
                    error: locale('settings.invoices.getError', { logNumber }),
                    logNumber,
                })
            )
            return
        }

        setInvoicesRequestStatus(createSuccessStatus())
        return invoices.sort(sortBillingDates)
    })

    const downloadPDF = useCallback(async (invoiceId, invoiceName) => {
        setPdfDownloadRequestStatus(createPendingStatus())
        const [error, invoicePdf] = await tryCatch(JobBoosterService.getInvoicePDF)(invoiceId)

        if (error) {
            const { logNumber } = logger.error.withError(error, 'Could not download PDF.')
            setPdfDownloadRequestStatus(
                createErrorStatus({
                    error: locale('settings.invoiceDownload.error', { logNumber }),
                    logNumber,
                })
            )
            errorNotification(locale('settings.invoiceDownload.error', { logNumber }))
            return
        }

        const pdfName = `${invoiceName || locale('invoice')}_${invoiceId}`
        prepareInvoicePDF(invoicePdf, pdfName)
        setPdfDownloadRequestStatus(createSuccessStatus())
    })

    view.update((fields, errors) => {
        fields.selectOrganizationInfo.visible = true
        fields.invoices.isLoading = invoicesRequestStatus
        fields.invoices.value = invoices
        fields.pdfDownload.isLoading = pdfDownloadRequestStatus

        fields.tasks.dictionary = [
            { label: locale('loadingPermissions'), ...permissionsState },
            { label: locale('companySelectorContentMessage'), ...getHierarchyNodeState },
        ]

        fields.pdfDownload.onTrigger = downloadPDF
        fields.back.onTrigger = process.exit

        errors.invoicesError = getErrorStructure(locale, invoicesRequestStatus?.error)
        errors.insufficientPermissionsError =
            permissionsState.isSuccessful && !permissionsState?.value?.settings?.invoices?.permissions?.read
                ? getErrorStructure(
                      locale,
                      createErrorStatus(locale('settings.invoices.insufficientPermissionsMessage'))
                  )
                : undefined
    })

    return async () => {
        process.ready()
        const permissions = await getPermissions()
        if (!permissions?.settings?.invoices?.permissions?.read) return

        const hierarchyNode = await getHierarchyNode()
        const invoices = await getInvoices(hierarchyNode.id)
        setInvoices(invoices)
    }
})

InvoicesProcess.label = 'InvoicesProcess'
