import { tryCatch } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { Stream } from '@lib/stream/stream2.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import JobBoosterService from '@services/job_booster_service.js'

const logger = Logger('SystemInfo', CATEGORIES.MAIN)
const infoData = {
    backendVersion: '',
}

const loadSystemInfo = Stream(async function* () {
    logger.info('loading system information')
    yield RemoteData.pending()
    const [error, systemInfo] = await tryCatch(JobBoosterService.getBackendVersionInfo)()
    if (error) {
        const errorMessage = 'Failed to load system information'
        const { logNumber } = logger.error.withError(error, errorMessage)
        throw RemoteData.error(errorMessage).logNumber(logNumber)
    }
    infoData.backendVersion = systemInfo.app.version
    return RemoteData.setValue(systemInfo.app.version).success()
})

export const SystemInfo = {
    get backendVersion() {
        return infoData.backendVersion
    },
    loadSystemInfo,
}
