import { CATEGORIES, Logger } from '@modules/logging/logger'
import { Process, stateOf, useCallback, useMemoLast, useState } from '@prospective/process-router'
import { Localization } from '@lib/i18n/localization'
import { CompanyInfoContext } from '@views/settings/company_info/company_info.context'
import { UserSession } from '@login/user-session'
import { Authorization } from '@modules/authorization/authorization'
import { Dictionaries } from '@modules/dictionaries/dictionaries'
import { tryCatch } from '@prospective/pms-js-utils'
import {
    createErrorStatus,
    createIdleStatus,
    createPendingStatus,
    createSuccessStatus,
    getErrorStructure,
} from '@utils/request_statuses'
import { successNotification } from '@components/modules/global_notifications/global_notifications'
import { ExitConfirmation } from '@views/misc/exit_confirmation/exit_confirmation.process'
import { useView } from '@lib/view_context/view_context_hooks'
import { hasErrors } from '@utils/validation_utils'
import { useRemoteDataStream } from '@utils/process_hooks.js'
import { ModuleController } from '@modules/module_controller.js'
import { HierarchySelection } from '@modules/hierarchy/hierarchy.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import JobBoosterService, { JobBoosterServiceError } from '@services/job_booster_service'

const compareCompanyInfo = (original, edited) => {
    return (
        original.companyName === edited.companyName &&
        original.street === edited.street &&
        original.additionalAddress === edited.additionalAddress &&
        original.postalCode === edited.postalCode &&
        original.city === edited.city &&
        original.state === edited.state &&
        original.country === edited.country
    )
}

const prepareCompanyInfo = (companyInfo, lang) => {
    return {
        id: companyInfo.id,
        homepage: companyInfo.homepage,
        companyName: companyInfo?.name?.[lang],
        street: companyInfo?.address?.street?.[lang],
        additionalAddress: companyInfo?.address?.address_extra?.[lang],
        postalCode: companyInfo?.address?.zip,
        city: companyInfo?.address?.city?.[lang],
        state: companyInfo?.address?.state || '',
        country: companyInfo?.address?.country,
    }
}

const toCompanyInfoDTO = company => ({
    id: company.id,
    homepage: company.homepage,
    name: {
        de: company?.companyName,
        en: company?.companyName,
        fr: company?.companyName,
        it: company?.companyName,
    },
    address: {
        country: company?.country,
        zip: company?.postalCode,
        state: company?.state,
        address_extra: {
            de: company?.additionalAddress,
            en: company?.additionalAddress,
            fr: company?.additionalAddress,
            it: company?.additionalAddress,
        },
        city: {
            de: company?.city,
            en: company?.city,
            fr: company?.city,
            it: company?.city,
        },

        street: {
            de: company?.street,
            en: company?.street,
            fr: company?.street,
            it: company?.street,
        },
    },
})

const logger = Logger('CompanyInfoProcess', CATEGORIES.MAIN)
export const CompanyInfoProcess = Process(({ process }) => {
    const { locale } = stateOf(Localization)
    const { isAdminUser } = stateOf(Authorization)
    stateOf(HierarchySelection)

    const [user, setUser] = useState()
    const view = useView(CompanyInfoContext)
    const userSession = stateOf(UserSession)
    const companyNodeId = userSession.companyNodeId
    const canLoadCompanyInfo = !isAdminUser || !!companyNodeId

    const [companyInfoRequestStatus, setCompanyInfoRequestStatus] = useState(createIdleStatus())
    const [saveCompanyInfoRequestStatus, setSaveCompanyInfoRequestStatus] = useState(createIdleStatus())
    const [originalCompanyInfo, setOriginalCompanyInfo] = useState()
    const [getHierarchyNode, getHierarchyNodeState] = useRemoteDataStream(ModuleController.getHierarchyNode)
    const [getUserDetails] = useRemoteDataStream(ModuleController.getUserDetails)
    const [getPermissions, permissionsState] = useRemoteDataStream(ModuleController.getPermissions, RemoteData.queued())

    process.beforeTerminate(async signal => {
        const isCompanyCompareEqual = compareCompanyInfo(originalCompanyInfo, view.values)
        if (!isCompanyCompareEqual) {
            const result = await process.child(ExitConfirmation())
            if (result === 'cancel') return signal.prevent('User cancelled navigation')
        }
    })

    const handleBack = useCallback(async () => {
        const isCompanyCompareEqual = compareCompanyInfo(originalCompanyInfo, view.values)
        if (!isCompanyCompareEqual) {
            const result = await process.child(ExitConfirmation())
            if (result === 'cancel') return
            if (result === 'discard') process.exit()
        }
        process.exit()
    })

    // TODO Stream migration
    const getCompanyInformation = useMemoLast(async (nodeId, user) => {
        setCompanyInfoRequestStatus(createPendingStatus())
        const userLang = user?.language
        const [error, data] = await tryCatch(JobBoosterService.getCompanyInformation)(nodeId)

        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            const { logNumber } = logger.error.withError(error, 'Could not load company information.')
            setCompanyInfoRequestStatus(
                createErrorStatus({
                    error: locale('settings.companyInfo.error', { logNumber }),
                    logNumber,
                })
            )
            return prepareCompanyInfo({}, userLang)
        }

        const companyInfo = prepareCompanyInfo(data, userLang)
        setCompanyInfoRequestStatus(createSuccessStatus())
        return companyInfo
    })

    // TODO Stream migration
    const saveCompanyInformation = useCallback(async user => {
        const validationResult = view.validate()
        if (hasErrors(validationResult)) return

        setSaveCompanyInfoRequestStatus(createPendingStatus())
        const nodeId = user?.nodeId
        const payload = toCompanyInfoDTO({
            id: originalCompanyInfo.id,
            homepage: originalCompanyInfo.homepage,
            companyName: view.values.companyName,
            street: view.values.street,
            additionalAddress: view.values.additionalAddress,
            postalCode: view.values.postalCode,
            city: view.values.city,
            state: view.values.state,
            country: view.values.country,
        })

        const [error, result] = await tryCatch(JobBoosterService.postCompanyInformation)(payload, nodeId)

        if (error && error.type !== JobBoosterServiceError.ABORT_ERROR) {
            const { logNumber } = logger.error.withError(error, 'Could not save company information.')
            setSaveCompanyInfoRequestStatus(
                createErrorStatus({
                    error: locale('settings.companyInfo.saveError', { logNumber }),
                    logNumber,
                })
            )
            return prepareCompanyInfo({}, user?.language)
        }

        const updatedCompanyInfo = prepareCompanyInfo(result, user?.language)
        setSaveCompanyInfoRequestStatus(createSuccessStatus())
        successNotification(locale('settings.companyInfo.successSubmit'))
        return updatedCompanyInfo
    })

    const populateFields = useMemoLast(company => {
        view.update(fields => {
            fields.companyName.value = company.companyName
            fields.street.value = company.street
            fields.additionalAddress.value = company.additionalAddress
            fields.postalCode.value = company.postalCode
            fields.city.value = company.city
            fields.state.value = company.state
            fields.country.value = company.country
        })
    })

    const handleSubmit = useCallback(async () => {
        const updatedInfo = await saveCompanyInformation(user)
        setOriginalCompanyInfo(updatedInfo)
    })

    view.update((fields, errors) => {
        fields.selectOrganizationInfo.visible = !canLoadCompanyInfo
        fields.company.isLoading = companyInfoRequestStatus
        fields.submit.isLoading = saveCompanyInfoRequestStatus

        fields.submit.onTrigger = handleSubmit
        fields.back.onTrigger = handleBack

        fields.tasks.dictionary = [
            { label: locale('loadingPermissions'), ...permissionsState },
            { label: locale('companySelectorContentMessage'), ...getHierarchyNodeState },
        ]

        errors.companyInfoError = getErrorStructure(locale, companyInfoRequestStatus?.error)
        errors.saveCompanyInfoError = getErrorStructure(locale, saveCompanyInfoRequestStatus?.error)
        errors.insufficientPermissionsError =
            permissionsState.isSuccessful && !permissionsState?.value?.settings?.companyInformation?.permissions?.read
                ? getErrorStructure(
                      locale,
                      createErrorStatus(locale('settings.companyInfo.insufficientPermissionsMessage'))
                  )
                : undefined
    })

    return async () => {
        process.ready()
        const permissions = await getPermissions()
        if (!permissions?.settings?.companyInformation?.permissions?.read) return

        const hierarchyNode = await getHierarchyNode()
        const user = await getUserDetails()
        setUser(user)

        const companyInfo = await getCompanyInformation(hierarchyNode.id, user)
        if (!originalCompanyInfo) setOriginalCompanyInfo(companyInfo)
        populateFields(companyInfo)
    }
})

CompanyInfoProcess.label = 'Company info process'
